
















import { Component, Vue } from "vue-property-decorator";

@Component({})
export default class ErrorView extends Vue {
  get numberOfError(): "403" | "404" | "500" {
    return this.$route.name as "403" | "404" | "500";
  }

  get title(): string {
    return this.$lang(`errorPage.${this.numberOfError}.title`);
  }

  get text(): string {
    return this.$lang(`errorPage.${this.numberOfError}.text`);
  }

  get img(): string {
    return require(`@/shared/assets/${this.numberOfError}.svg`);
  }
}
